import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputText } from '@jobber/components/InputText';
import { Select, Option } from '@jobber/components/Select';
import Pagination from '../../../components/Common/Pagination';
import { IoMdArrowDropdown, IoMdArrowDropup, IoMdSave } from 'react-icons/io';
import { deleteFromServer, getFromServer, getFromServerHardToken, postToServer, putToServer } from '../../../components/Common/requests';
import { Glimmer } from '@jobber/components/Glimmer';
import { checkStatus, formatDateTimeDay, formatNumber, formatYearDateTimeDay } from '../../../components/Common/Validations';
import { Heading } from '@jobber/components/Heading';
import { Menu } from '@jobber/components/Menu';
import { Button } from '@jobber/components/Button';
import { Checkbox } from '@jobber/components/Checkbox';
import { Col, Dropdown, DropdownItem, Row } from 'react-bootstrap';
import { showToast } from '@jobber/components/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { json, useLocation, useNavigate } from 'react-router';
import { Spinner } from '@jobber/components/Spinner';
import { Divider } from '@jobber/components/Divider';
import TableDescription from './TableDescription';
import './table.css';

// data
import { columnVisibility, widthData } from './TableData';
import { FiEdit } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import TableTabs from './TableTabs';
import TotalLabels from '../TotalLabels/TotalLabels';
import { Link } from 'react-router-dom';
import { setRecycleTabsR, setTabs } from '../../../store/AdvanceQuotes/TabsSlice';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import useDetectBackNavigation from '../../../utils/useDetectBackNavigation';
import { setPreservedR, setQuotesDataR, setSearchR } from '../../../store/AdvanceQuotes/QuotesTable';
import { MdDeleteForever } from 'react-icons/md';
import FiltersIndex from './FiltersIndex';
import QuoteSocket from '../../../socket/QuoteSocket';
import TableRow from './TableRow';
import { useQuoteFilter } from '../FilterQuery/QuoteFilterQuery';
import { setIsSavedFilterAppliedFilters, setQuoteFilterQuery, setReduxEntries } from '../../../store/quoteFilterSlice';
import LoadData from '../LoadData/LoadData';
import ColorPicker from './ColorPicker';
import ConfirmModal from '../../Index/TagCloudCategory/ConfirmModal';
import AuditData from '../LoadData/AuditData';
// import LoadQBData from '../LoadData/LoadQBData';
import { SiQuickbooks } from "react-icons/si";
import JobberLogo from '../../../assets/logoBlack.svg';

export default function Table({ tagCloud, reload }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const [completeData, setCompleteData] = useState({});
	const [data, setData] = useState([]);
	const [totalPage, setTotalPage] = useState(1);
	const [entries, setEntries] = useState(null);
	const [totalEntries, setTotalEntries] = useState(null);
	const [limit, setLimit] = useState(10);
	const [loading, setLoading] = useState(true);
	const { updateFilter, buildQuery, filters, addFilterList } = useQuoteFilter();
	const location = useLocation();
	const [isAdvanceQuotes, setIsAdvanceQuotes] = useState(location?.pathname.includes('recycle') ? false : true);
	useEffect(() => {
		setIsAdvanceQuotes(location?.pathname.includes('recycle') ? false : true);
		setData([]);
	}, [location]);
	// useEffect(() => {
	//   setReloadFilter(!reloadFilter);
	// }, []);
	const [curentTabId, setCurentTabId] = useState(localStorage.getItem('cTab') || null);
	const reduxFilters = useSelector((store) => store.filters);
	const reduxQuotesTable = useSelector((store) => store.quotesTable);
	const tabs = useSelector((store) => store.tabs);
	const [color, setColor] = useState('#000000');
	const [search, setSearch] = useState(reduxQuotesTable?.isPreserved ? reduxQuotesTable.search || '' : '');

	const [sortConfig, setSortConfig] = useState({
		key: null,
		direction: 'ASCENDING'
	});

	useEffect(() => {
		const cLoc = location.pathname.includes('recycle') ? false : true;
		if (cLoc) {
			const tempFilter = JSON.parse(localStorage.getItem('tempQuotesFilter')) || {};
			if (tempFilter?.limit) setLimit(parseInt(tempFilter?.limit?.[0]));
			if (tempFilter?.page) setCurrentPage(parseInt(tempFilter?.page?.[0]));
			if (tempFilter?.sort_key)
				setSortConfig({
					key: tempFilter?.sort_key?.[0],
					direction: tempFilter?.sort_direction?.[0]
				});
		}
	}, []);

	// column visibility
	const [visibleColumns, setVisibleColumns] = useState({
		notes: true,
		name: true,
		title: true,
		address: true,
		zipCode: true,
		phone: true,
		email: true,
		approved: true,
		// pending: true,
		details: true,
		// autoRenew: true,
		// cash: true,
		totalCost: true,
		amountPaid: true,
		amountOwed: true,
		QBtotalCost: false,
		QBamountPaid: false,
		QBamountOwed: false,
		totalAmountOwed: false,
		date: true,
		approvedDate: true
	});

	const toggleColumn = (columnName) => {
		setVisibleColumns((prev) => ({
			...prev,
			[columnName]: !prev[columnName]
		}));
		setTimeout(() => {
			try {
				$('table').resizableColumns('destroy').resizableColumns();
			} catch { }
		}, 500);
	};

	// ++++++++++++++++++

	// for user settings
	const [dragtableState, setDragtableState] = useState({});
	useEffect(() => {
		let localSettings = localStorage.getItem('userQuotesSettings');
		if (!localSettings) {
			localStorage.removeItem('authUser');
			navigate('/login');
		} else {
			localSettings = JSON.parse(localSettings);
			setVisibleColumns(localSettings?.columnVisibility);
			setDragtableState(localSettings?.columnOrder);
			Object.entries(localSettings?.columnWidths)?.map(([key, value]) => {
				localStorage.setItem(`quotes-${key}`, value);
			});
		}
	}, []);

	const [saveLoader, setSaveLoader] = useState(false);
	const saveUserSettingsHandler = async () => {
		let colWidths = {};
		const width = widthData;
		width.forEach((w) => {
			let temp = localStorage.getItem(`quotes-${w}`);
			if (temp === null || temp === '' || temp == 'null') {
				colWidths[w] = 5;
			} else {
				colWidths[w] = temp;
			}
		});
		const body = {
			columnOrder: dragtableState,
			columnVisibility: visibleColumns,
			columnWidths: colWidths
		};
		setSaveLoader(true);
		const result = await postToServer('page-settings', body);
		if (result.status) {
			localStorage.setItem('userQuotesSettings', JSON.stringify(body));
			showToast({
				message: 'Quotes settings updated successfully'
			});
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setSaveLoader(false);
	};
	// ++++++++++++++++++++

	// dragtable / column order
	const initialzeTable = async () => {
		const localSettings = JSON.parse(localStorage.getItem('userQuotesSettings'));
		try {
			$('#quotes__table').dragtable({
				dragHandle: '.drag__handle',
				persistState: function (table) {
					table.el.find('th').each(function (i) {
						if (this.id != '') {
							table.sortOrder[this.id] = i;
						}
					});
					setDragtableState(table.sortOrder);
				},
				restoreState: localSettings?.columnOrder,
				dragaccept: '.accept:not(.notAccept)'
			});
		} catch { }
	};

	// +++++++++++++++++++++++++

	useEffect(() => {
		const cLoc = location.pathname.includes('recycle') ? false : true;
		if (cLoc && Object.entries(reduxQuotesTable?.data).length > 0) {
			// $("#quotes__table").dragtable("destroy");
			// const tempFilter2 = JSON.parse(localStorage.getItem('tempQuotesFilter')) || {};
			// console.log('first', reduxQuotesTable?.data, tempFilter2);
			// if (tempFilter2) {
			//   dispatch(setQuoteFilterQuery(tempFilter2));
			//   dispatch(setIsSavedFilterAppliedFilters());
			// }
			//   setCompleteData(reduxQuotesTable?.data)
			const cPageId = localStorage.getItem(isAdvanceQuotes ? 'cTab' : 'rcTab');
			if (cPageId) {
				setCurentTabId(cPageId);
			}
			setCurrentPage(reduxQuotesTable?.data.currentPage);
			if (reduxQuotesTable?.isPreserved) {
				setData(reduxQuotesTable?.data.quotes);
				setLoading(false);
			}
			setTotalAmount(reduxQuotesTable?.data?.page);
			setEntries(reduxQuotesTable?.data?.totalQuotes);
			setTotalEntries(reduxQuotesTable?.data?.allQuotesTotal);
			dispatch(setReduxEntries(reduxQuotesTable?.data?.totalQuotes));
			setTotalPage(reduxQuotesTable?.data?.totalPages);
			setTimeout(async () => {
				await initialzeTable();
			}, 100);
		}
	}, []);

	const [socketCalled, setSocketCalled] = useState(false);
	const [totalAmount, setTotalAmount] = useState({});

	const fetchRecycleData = async (destroy = false) => {
		setLoading(true);
		const queryString = buildQuery();
		if (destroy) {
			try {
				$('#quotes__table').dragtable('destroy');
			} catch (error) { }
		}
		const tableData = await getFromServer(`recycled-quotes?${queryString}`);
		if (tableData.status) {
			console.log(tableData.data);
			dispatch(setQuotesDataR(tableData.data));
			setData(tableData.data.quotes);
			setCompleteData(tableData.data);
			setEntries(tableData?.data?.totalQuotes);
			setTotalEntries(tableData?.data?.allQuotesTotal);
			dispatch(setReduxEntries(tableData?.data?.totalQuotes));
			setTotalPage(tableData?.data?.totalPages);
			setVisibleColumns({
				...visibleColumns,
				totalCost: true,
				amountPaid: true,
				amountOwed: true,
				QBtotalCost: true,
				QBamountPaid: true,
				QBamountOwed: true,
				totalAmountOwed: true
			})

			setTimeout(async () => {
				await initialzeTable();
			}, 100);

			setTimeout(() => {
				try {
					$('table').resizableColumns('destroy').resizableColumns({
						store: window.store
					});
				} catch { }
			}, 500);
		} else {
			showToast({
				message: tableData.message || tableData.error,
				variation: 'error'
			});
		}
		setLoading(false);
	};

	const [columnTab, setColumnTab] = useState('');
	const fetchTableData = async (defaultPageId, destroy = false) => {
		setEditId(-1);
		if (!defaultPageId) defaultPageId = tabs.tabs?.[0]?._id;
		setCurentTabId(defaultPageId);
		const queryString = buildQuery();
		if (defaultPageId) {
			const tableData = await getFromServer(`page/${defaultPageId}?${queryString}`);
			if (tableData.status) {
				reloadHeader();
				if (destroy) {
					try {
						$('#quotes__table').dragtable('destroy');
					} catch (error) { }
				}
				dispatch(setQuotesDataR(tableData.data));
				setData(tableData.data.quotes);
				setCompleteData(tableData.data);
				setTotalAmount(tableData.data?.page);
				setEntries(tableData?.data?.totalQuotes);
				setTotalEntries(tableData?.data?.allQuotesTotal);
				dispatch(setReduxEntries(tableData?.data?.totalQuotes));
				setTotalPage(tableData?.data?.totalPages);
				setColumnTab(defaultPageId)

				setTimeout(async () => {
					await initialzeTable();
				}, 100);

				setTimeout(() => {
					try {
						$('table').resizableColumns('destroy').resizableColumns({
							store: window.store
						});
					} catch { }
				}, 500);
			} else {
				showToast({
					message: tableData.message || tableData.error,
					variation: 'error'
				});
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		if (totalAmount?._id === tabs.tabs?.[0]?._id && totalAmount?.pageName === 'Default Page') {
			let localSettings = localStorage.getItem('userQuotesSettings');
			if (!localSettings) {
				localStorage.removeItem('authUser');
				navigate('/login');
			} else {
				localSettings = JSON.parse(localSettings);
				setVisibleColumns(localSettings?.columnVisibility);
			}
		} else {
			if (Object.keys(totalAmount).length === 0) {
				let localSettings = localStorage.getItem('quoteCustomPageColumns');
				if (localSettings) {
					setVisibleColumns(JSON.parse(localSettings))
				}
			} else {
				setVisibleColumns((prev) => ({
					...prev,
					totalCost: totalAmount?.columnPreferences?.totalCost,
					amountPaid: totalAmount?.columnPreferences?.amountPaid,
					amountOwed: totalAmount?.columnPreferences?.amountOwed,
					QBtotalCost: totalAmount?.columnPreferences?.QBtotalCost,
					QBamountPaid: totalAmount?.columnPreferences?.QBamountPaid,
					QBamountOwed: totalAmount?.columnPreferences?.QBamountOwed,
					totalAmountOwed: totalAmount?.columnPreferences?.totalAmountOwed
				}));
			}
		}
	}, [columnTab])

	useEffect(() => {
		localStorage.setItem('quoteCustomPageColumns', JSON.stringify(visibleColumns));
	}, [visibleColumns])

	const fetchData = async () => {
		const cLoc = location.pathname.includes('recycle') ? false : true;
		const result = cLoc ? await getFromServer(`pages`) : await getFromServer(`archieve-pages`);
		if (result.status) {
			const newData = [
				...result.data,
				{
					tags: {
						include: [],
						exclude: []
					},
					_id: 'recycle',
					pageName: 'Recycle Page'
				}
			];
			if (cLoc) {
				dispatch(setTabs(newData));
			} else {
				dispatch(setRecycleTabsR(result.data));
			}
			if (result.data?.length > 0) {
				// const localId = localStorage.getItem(cLoc ? 'cTab' : 'rcTab');
				const localId = localStorage.getItem('cTab');
				let defaultPageId = localId;
				if (!localId) {
					defaultPageId = result.data?.[0]._id;
					localStorage.setItem(cLoc ? 'cTab' : 'rcTab', result.data?.[0]._id);
				}
				if (filters?.searchTerm?.[0] == '') {
					await fetchTableData(defaultPageId);
				} else {
					updateFilter('searchTerm', '');
				}
			} else {
				if (!isAdvanceQuotes) {
					dispatch(setQuotesDataR([]));
					setData([]);
					setCompleteData([]);
					setTotalAmount(0);
					setEntries(0);
					setTotalEntries(0);
					dispatch(setReduxEntries([]));
					setTotalPage(0);
				}
			}
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setLoading(false);
	};

	useEffect(() => {
		// console.log(reduxQuotesTable?.isPreserved, 'called');
		if (!reduxQuotesTable?.isPreserved) {
			setLoading(true);
			fetchData();
		} else {
			console.log('chnagig serachterm');

			updateFilter('searchTerm', '');
		}
	}, [location]);

	const [isRecycle, setIsRecycle] = useState(false);
	const tabChangeHandler = async (pageId) => {
		setEditId(-1);
		if (pageId == 'recycle') {
			setIsRecycle(true);
		} else {
			setIsRecycle(false);
			localStorage.setItem(isAdvanceQuotes ? 'cTab' : 'rcTab', pageId);
			setCurentTabId(pageId);
		}
		// reloadHeader();
		updateFilter('page', 1);
		setCurrentPage(1);
	};

	// sorting
	const onSort = (key) => {
		updateFilter('sort_key', key);
		if (sortConfig?.key === key && sortConfig?.direction === 'ASCENDING') {
			setSortConfig({ key, direction: 'DESCENDING' });
			updateFilter('sort_direction', 'DESCENDING');
		} else {
			setSortConfig({ key, direction: 'ASCENDING' });
			updateFilter('sort_direction', 'ASCENDING');
		}
	};

	const getIconStyle = (key, direction) => {
		return sortConfig.key === key && sortConfig.direction === direction ? 'fs-5 text-dark' : 'fs-5 text-muted opacity-25';
	};

	// applying filters
	const [pageLoad, setPageLoad] = useState(true);
	const [showHeader, setShowHeader] = useState(true);
	const reloadHeader = () => {
		setShowHeader(false);
		setTimeout(() => {
			setShowHeader(true);
		}, 10);
	};
	useEffect(() => {
		setEditId(-1);
		if (!pageLoad) {
			if (isRecycle) {
				reloadHeader();
				const queryString = buildQuery();
				if (!queryString || queryString?.length <= 0) {
					setCurrentPage(1);
				}
				if (parseInt(filters?.page?.[0])) {
					setCurrentPage(parseInt(filters?.page?.[0]));
				}
				setLoading(true);
				fetchRecycleData(true);
			} else {
				if (!reduxQuotesTable?.isPreserved) {
					reloadHeader();
					const queryString = buildQuery();
					if (!queryString || queryString?.length <= 0) {
						setCurrentPage(1);
					}
					if (parseInt(filters?.page?.[0])) {
						setCurrentPage(parseInt(filters?.page?.[0]));
					}
					setLoading(true);
					fetchTableData(curentTabId, true);
				} else {
					dispatch(setPreservedR(false));
				}
			}
		} else {
			setPageLoad(false);
		}
	}, [filters]);

	useEffect(() => {
		if (!pageLoad) {
			reloadHeader();
			if (isRecycle) {
				fetchRecycleData(true);
			} else {
				fetchTableData(curentTabId, true);
			}
		} else {
			setPageLoad(false);
		}
	}, [socketCalled]);

	// sync quotes
	const [syncLoading, setSyncLoading] = useState(false);
	const handleSynQuotes = async () => {
		reloadHeader();
		setLoading(true);
		setSyncLoading(true);
		setCurrentPage(1);
		// await fetchTableData(curentTabId, true);
		addFilterList({});
		setSyncLoading(false);
		setLoading(false);
	};

	// dropdown
	const [showDropdown, setShowDropdown] = useState(false);
	const dropdownToggleRef = useRef(null);

	const toggleDropdown = () => {
		setShowDropdown(!showDropdown);
	};
	// const handleClickOutside = (event) => {
	// 	if (showDropdown) {
	// 		setShowDropdown(false);
	// 	}
	// };
	// useEffect(() => {
	// 	document.addEventListener("mousedown", handleClickOutside);
	// 	return () => {
	// 		document.removeEventListener("mousedown", handleClickOutside);
	// 	};
	// }, []);
	// +++++++++++++++++++++++++

	// useEffect(() => {
	// 	const tempFilter = JSON.parse(localStorage.getItem("tempFilter"));
	// 	if (tempFilter?.searchTerm) setSearch(tempFilter?.searchTerm?.[0]);
	// 	if (tempFilter?.limit) setLimit(parseInt(tempFilter?.limit?.[0]));
	// }, []);
	// const clearFilters = () => {
	// 	dispatch(setFilterQuery({}));
	// 	addFilterList({});
	// 	setLimit(10);
	// 	setSearch("");
	// 	reload();
	// };

	// sticky
	const [isSticky, setIsSticky] = useState(false);

	const handleScroll2 = () => {
		const stickyElement = document.querySelector('.check__sticky');
		if (stickyElement) {
			const offsetTop = stickyElement.getBoundingClientRect().top;
			setIsSticky(offsetTop <= 270);
		}
	};
	useEffect(() => {
		window.addEventListener('scroll', handleScroll2);
		return () => {
			window.removeEventListener('scroll', handleScroll2);
		};
	}, []);

	// ++++++++++++++++++++++++

	// search debbouncing
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search);

	const updateFilters = useCallback((filterName, value) => {
		updateFilter(filterName, value);
	}, []);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedSearchTerm(search);
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [search]);

	useEffect(() => {
		if (!pageLoad) {
			updateFilters('searchTerm', debouncedSearchTerm);
		} else {
			setPageLoad(false);
		}
	}, [debouncedSearchTerm]);

	// hover
	const [hoverIndex, setHoverIndex] = useState(-1);
	const [editId, setEditId] = useState(-1);

	// editing table columns
	const [columnLoader, setColumnLoader] = useState(false);
	const [columnDataID, setColumnDataID] = useState(null);
	const [columnData, setColumnData] = useState([]);
	const [tempColumnData, setTempColumnData] = useState([]);
	const handleEditButton = (row, index) => {
		setColumnDataID(row?.client);
		setEditId(index);
		let tempColumnData = [];
		row?.uniquePropertyAddresses?.forEach((c) => {
			tempColumnData.push({
				propertyId: c?.propertyId,
				totalCost: c.groupTotalCost || 0,
				amountPaid: c.groupAmountPaid || 0,
				amountOwed: c.groupAmountOwed || 0
			});
		});
		setColumnData(tempColumnData);
		setTempColumnData(tempColumnData);
	};
	const handleDeleteButton = async (row) => {
		const newData = data?.filter((item) => item._id != row._id);
		setData(newData);
		setEntries(parseInt(entries) - 1);
		setTotalEntries(parseInt(totalEntries) - 1);
	};

	function calculateDifferences(array1, array2) {
		const differences = array1.map((item1, index) => {
			const item2 = array2[index]; // Assuming corresponding objects are at the same index

			// Initialize an object to store the differences
			let difference = {};
			console.log(item1, item2);

			// Calculate differences if the keys match your criteria
			if ('totalCost' in item1 && 'groupTotalCost' in item2) {
				difference['amountCost'] = item2.groupTotalCost - item1.totalCost;
			}
			if ('amountPaid' in item1 && 'groupAmountPaid' in item2) {
				difference['amountPaid'] = item2.groupAmountPaid - item1.amountPaid;
			}
			if ('amountOwed' in item1 && 'groupAmountOwed' in item2) {
				difference['totalOwed'] = item2.groupAmountOwed - item1.amountOwed;
			}

			return difference;
		});

		// Reduce the array of differences into a single object, summing up the values of matching keys
		const totalDifferences = differences.reduce((acc, curr) => {
			Object.keys(curr).forEach((key) => {
				// If the key already exists in the accumulator, add the current value to it
				// Otherwise, initialize it with the current value
				acc[key] = (acc[key] || 0) + curr[key];
			});
			return acc;
		}, {});

		return totalDifferences;
	}

	const handleSaveColumnRowData = async () => {
		setColumnLoader(true);
		const result = await putToServer(`quote/${columnDataID}/${curentTabId}`, columnData);
		console.log(result);
		if (result.status) {
			const val = result.data?.[0];
			const newData = [...data];
			newData[editId] = val;
			setData(newData);
			const differences = calculateDifferences(tempColumnData, val?.uniquePropertyAddresses);
			console.log(differences);
			const totalData = {
				...totalAmount,
				amountCost: totalAmount?.amountCost + differences?.amountCost,
				amountPaid: totalAmount?.amountPaid + differences?.amountPaid,
				totalOwed: totalAmount?.totalOwed + differences?.totalOwed
			};
			setTotalAmount(totalData);
			dispatch(setQuotesDataR({ ...reduxQuotesTable?.data, quotes: newData, page: totalData }));
		}
		setColumnData([]);
		setColumnLoader(false);
		setEditId(-1);
	};

	// export handler
	const [excelLoading, setExcelLoading] = useState(false);
	const exportToCSV = (apiData, fileName) => {
		// Step 1: Convert JSON to Worksheet
		const worksheet = XLSX.utils.json_to_sheet(apiData);

		// Step 2: Create a Workbook
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

		// Step 3: Write Workbook to binary string
		const excelBuffer = XLSX.write(workbook, {
			bookType: 'xlsx',
			type: 'array'
		});

		// Step 4: Create a Blob with explicit MIME type
		const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		const data = new Blob([excelBuffer], { type: mimeType });

		FileSaver.saveAs(data, fileName + '.xlsx');

		showToast({
			message: 'Excel file downloaded'
		});
	};
	const handleExport = async () => {
		setExcelLoading(true);
		const result = await getFromServer(`page-excel/${curentTabId}/${isRecycle}`);
		if (result.status) {
			const newData = result.data.quotes?.flatMap((qa) => {
				return qa?.uniquePropertyAddresses?.map((q) => {
					const myObj = {};
					myObj['Name'] = qa.clientFirstName + ' ' + qa.clientLastName;
					myObj['Title'] = qa.quoteTitle;
					myObj['Address'] = q.street;
					myObj['Zip Code'] = q.postalCode;
					myObj['Phone'] = qa.clientPhone;
					myObj['Email'] = qa.clientEmail;
					// myObj['Approved'] = qa.approved;
					myObj['Total Cost'] = q.groupTotalCost;
					myObj['Amount Paid'] = q.groupAmountPaid;
					myObj['Amount Owed'] = q.groupAmountOwed;
					myObj['QB Total Cost'] = qa.totalQBCost;
					myObj['QB Amount Paid'] = qa.amountQBPaid;
					myObj['QB Amount Owed'] = qa.amountQBOwed;
					myObj['Date'] = formatYearDateTimeDay(qa.quote_createdAt);
					myObj['Approved Date'] = formatYearDateTimeDay(qa.approved_at);
					return myObj;
				});
			});

			console.log(newData);
			exportToCSV(newData, 'Quotes');
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
		setExcelLoading(false);
	};

	// double click handller
	const handleDoubleClick = async (e, rowId, highlight) => {
		e.stopPropagation();
		e.preventDefault();
		if (isRecycle) return
		const body = {
			color,
			isHighlight: highlight ? false : true
		};
		const result = await putToServer(`highlight-quote/${rowId}/${curentTabId}`, body);
		if (result.status) {
			const newData = [...data];
			const index = data.findIndex((item) => item?.client === rowId);
			if (index > -1) {
				newData[index] = result.data?.[0];
				setData(newData);
				dispatch(setQuotesDataR({ ...reduxQuotesTable?.data, quotes: newData }));
			}
		} else {
			showToast({
				message: result.message || result.error,
				variation: 'error'
			});
		}
	};

	// color changer
	useEffect(() => {
		const localColor = localStorage.getItem('color');
		if (localColor) {
			setColor(localColor);
		}
	}, []);
	const colorChangehandler = (e) => {
		const value = e;
		console.log(value);
		setColor(value);
		localStorage.setItem('color', value);
	};

	const keysToRemove = ["totalCost", "amountPaid", "amountOwed", "QBtotalCost", "QBamountPaid", "QBamountOwed", "totalAmountOwed"];

	const filteredColumns = (curentTabId === tabs.tabs?.[0]?._id || isRecycle)
		? columnVisibility
		: columnVisibility?.filter(item => !keysToRemove.includes(item.key));

	return (
		<div>
			<QuoteSocket socketCalled={() => setSocketCalled(!socketCalled)} />
			<div>
				{/* <div className="search__sticky" style={{ zIndex: "10" }}> */}
				<div className="row mb-3">
					<div className="col-lg-3 col-md-4 d-flex gap-2 align-items-center">
						<p className="pt-3">Show</p>
						<Select
							size="small"
							onChange={(e) => {
								updateFilter('limit', e);
								updateFilter('page', currentPage);
								setLimit(e);
							}}
							value={limit}
						>
							<Option value="all">All (Takes time to load)</Option>
							<Option value="10">10</Option>
							<Option value="20">20</Option>
							<Option value="50">50</Option>
						</Select>
						<p className="pt-3">entries</p>
					</div>
					<div className="col-lg-2 col-md-1"></div>
					<div className="col-md-7">
						<div className="d-flex gap-2 justify-content-end align-items-center" style={{ paddingBottom: '1rem' }}>
							{isAdvanceQuotes && (
								<>
									<AuditData
										curentTabId={curentTabId}
										reloadData={() => {
											setLoading(true);
											setSocketCalled(!socketCalled);
										}}
									/>
									<ColorPicker color={color} colorChangehandler={colorChangehandler} />
									{/* <FiltersIndex /> */}
									<LoadData handleSynQuotes={handleSynQuotes} />
									{/* <LoadQBData /> */}
								</>
							)}
							<div style={{ position: 'relative' }}>
								<Button label={'Manage Columns'} ref={dropdownToggleRef} onClick={toggleDropdown} />
								<div style={{ zIndex: '11', position: 'absolute' }}>
									{/* <div className="dropdown__sticky" style={{ zIndex: "10" }}> */}
									<Dropdown show={showDropdown}>
										<Dropdown.Menu>
											<DropdownItem>Show and Hide Columns</DropdownItem>
											<div className="ms-3">
												{filteredColumns &&
													filteredColumns?.map((c) => {
														return (
															<>
																<Checkbox label={c.name} checked={visibleColumns[c.key]} onChange={() => toggleColumn(c.key)} />
																<br />
															</>
														);
													})}
											</div>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>
							{saveLoader ? (
								<Spinner size="base" />
							) : isAdvanceQuotes ? (
								<Button label={'Save Settings'} type="secondary" onClick={saveUserSettingsHandler} />
							) : (
								''
							)}
						</div>
					</div>
				</div>
			</div>
			<div>
				<TableTabs tabChangeHandler={tabChangeHandler} setVisibleColumns={setVisibleColumns} />
				<Row className='mt-3'>
					<Col md={5}>
						{isAdvanceQuotes && (
							<div className="d-flex gap-3 pt-3">
								{excelLoading ? <Spinner size="base" /> : <Button label={'Export'} icon="export" onClick={handleExport} />}
								<FiltersIndex />
								<InputText
									placeholder="Search"
									name="firstName"
									size="small"
									value={search}
									onChange={(e) => {
										dispatch(setSearchR(e));
										setSearch(e);
									}}
								/>
							</div>
						)}
					</Col>
					<Col>
						<div className='d-flex justify-content-end gap-2'>{isAdvanceQuotes && !isRecycle ? <>
							<TotalLabels visibleColumns={visibleColumns} totalAmount={totalAmount} loading={loading} />
							<TotalLabels visibleColumns={visibleColumns} isQB totalAmount={totalAmount} loading={loading} />
						</> : ''}</div>
					</Col>
				</Row>
				<table data-resizable-columns-id="quotes" id="quotes__table" className={`th--border table stable table-hover advanceTable`}>
					{showHeader && (
						<thead>
							<tr className={`sticky check__sticky ${isSticky ? 'tag__category--sticky' : ''}`}>
								{/* column headers  */}
								{columnVisibility &&
									columnVisibility?.map((col, index) => {
										return (
											<th
												key={index}
												data-resizable-column-id={col.colName}
												id={col.colName}
												className={`accept ${visibleColumns[col.key] ? '' : 'd-none'}`}
											>
												{col?.filter ? (
													<div className="d-flex gap-2 align-items-center justify-content-between">
														{col?.logo && (
															<>
																{col?.logo === 'jobber' ? (
																	<img src={JobberLogo} style={{ width: '15px', aspectRatio: 1 }} alt="" />
																) : (
																	<SiQuickbooks className='fs-5' />
																)}
															</>
														)}
														<div className="drag__handle">{col.name.split(' ')[0] !== 'QB' ? col.name : col.name.split(' ').slice(1).join(' ')}</div>
														<div
															className="icon-container"
															onClick={(e) => {
																e.stopPropagation();
																onSort(col?.filter);
															}}
														>
															<IoMdArrowDropup className={`icon-up ${getIconStyle(col?.filter, 'ASCENDING')}`} />
															<IoMdArrowDropdown className={`icon-down ${getIconStyle(col?.filter, 'DESCENDING')}`} />
														</div>
													</div>
												) : (
													<div className="drag__handle">{col.name}</div>
												)}
											</th>
										);
									})}
								{isAdvanceQuotes && <th></th>}
							</tr>
						</thead>
					)}
					<tbody>
						{loading
							? Array.from({ length: 2 }, (_, index) => (
								<tr>
									{Object.entries(visibleColumns)?.map(([key, value], index) => (
										<React.Fragment key={index}>
											{value !== 0 && value && (
												<td>
													<Glimmer shape="rectangle" size="base" timing="speed" />
												</td>
											)}
										</React.Fragment>
									))}
									{isAdvanceQuotes && (
										<td>
											<Glimmer shape="rectangle" size="base" timing="speed" />
										</td>
									)}
								</tr>
							))
							: data &&
							data?.map((s, k) => {
								const matchingHighlight = s?.highlight?.find((page) => page.pageId === curentTabId);
								return (
									<TableRow
										s={s}
										isAdvanceQuotes={isAdvanceQuotes}
										curentTabId={curentTabId}
										k={k}
										matchingHighlight={matchingHighlight}
										handleDoubleClick={handleDoubleClick}
										visibleColumns={visibleColumns}
										editId={editId}
										columnData={columnData}
										setColumnData={setColumnData}
										columnLoader={columnLoader}
										handleSaveColumnRowData={handleSaveColumnRowData}
										setEditId={setEditId}
										handleEditButton={handleEditButton}
										handleDeleteButton={handleDeleteButton}
										isRecycle={isRecycle}
										reloadData={() => {
											setLoading(true);
											setSocketCalled(!socketCalled);
										}}
									/>
								);
							})}
					</tbody>
				</table>
			</div>
			{data?.length <= 0 && !loading ? <div className="text-center">No data found</div> : ''}
			<div className="d-md-flex justify-content-between">
				{isRecycle ? (
					<div>
						<span>{entries} entries</span> <br />
					</div>
				) : (
					<div className="">
						<span>{totalEntries} entries before merging quotes</span> <br />
						<span>{entries} entries after merging quotes</span>
					</div>
				)}

				{totalPage == 1 ? (
					''
				) : (
					<div className="">
						<Pagination
							currentPage={currentPage}
							totalPages={totalPage}
							onPageChange={(page) => {
								setCurrentPage(page);
								updateFilter('page', page);
								window.scrollTo(0, 0);
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
